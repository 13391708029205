<template>
    <div class="games-promotions page">
        <b-container>
            <b-row class="games-promotions__content" v-if="games.length > 0">
                <b-col cols="12" sm="6" v-for="game in games" :key="game.id" class="games-promotions-item-wrap">                                        
                    <PromoGamesCard :promoGame="game" v-inview="'fade-up'" :type="'game'"/>
                </b-col>
            </b-row>
            <b-row v-else class="games-promotions__content">
                <b-col cols="12" class="d-flex justify-content-center align-items-center">
                    Átmenetileg kifogytunk a játékokból, gyere vissza később!
                </b-col>
            </b-row>
        </b-container>
        <PageLoading /> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import PromoGamesCard from '@/components/pages/promoGames/PromoGamesCard.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    mixins: [apiH],
    components:{
        PromoGamesCard,
        PageLoading
    },
    data(){
        return{
            games: []
        }
    },
    created(){
        this.getPromotions()
    },
    mounted(){
        this.setMetaTags({
            title: 'Soproni - Játékok', 
            desc: null, 
            keywords: null, 
            ogType: 'website', 
            ogTitle: 'Soproni - Játékok',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:null
        })
    },
    methods:{
        getPromotions(){
            let _this = this

            _this.get('game',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],                                                
                        ],
                        order_by:[['sort_order', 'asc'], ['published_date', 'desc']]
                    }
                },
                function(resp){                                        
                    _this.games = resp.data.game                  
                    
                }, function(err){
                    console.log(err);
                    _this.games = []
                }
            )
        }
    }
}
</script>