<template>
    <div class="pageloading" v-if="pageLoaded == false">    
        <!-- Kis türelmet... -->
        <div class="dots-wrap">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            pageLoaded: false,
            checkPageLoad: null
        }
    },
    mounted(){                         
        let _this = this

        this.checkPageLoad = setInterval(() => {
            _this.checkPageLoaded()                                    
        }, 300); 
    },
    methods:{
        checkPageLoaded(){
            let _this = this
                        
            // console.log(document.readyState)                        
            if (document.readyState === 'complete') {                    
                _this.pageLoaded = true
                clearInterval(_this.checkPageLoad)
            }                                  
        }        
    }
}
</script>