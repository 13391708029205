<template>
    <div 
        class="promo-games__item"
        :style="setBgrImg(promoGame.background_image)"
    >
        <div class="text">
            <div class="text__inner" v-html="promoGame.description"></div>
            <div class="btn-wrap">
                <a v-if="promoGame.target_url" :href="promoGame.target_url" class="btn btn--green-border btn--with-arrow" target="_blank" @click="setGTM(promoGame.name)"><span>Részletek</span></a>
            </div>
        </div>
        <div class="img-wrap">
            <img v-if="promoGame.image && isDesctop"                             
                :src="src_url + itemSrc(promoGame.image, 'w512h820')"
                :alt="promoGame.name"
                class="main-img img-fluid d-show--desctop"  
            />
            <img v-if="promoGame.mobile_image"                             
                :src="src_url + itemSrc(promoGame.mobile_image, 'w576h392')"
                :alt="promoGame.name"
                class="main-img--mobile img-fluid d-show--mobile"  
            />                           
        </div>                       
    </div>
</template>

<script>
export default {
    props:['promoGame', 'type'],
    data(){
        return{
            
        }
    },
    created(){
        this.onDesctop()
    }, 
    methods:{
          setGTM(gameName){          
            let _this = this  
            let itemType = null
            let actionText = ''
            let buttonText = ''

            if (_this.type == 'promotion') {
                itemType = 'promotion'                
            } else if (_this.type == 'game') {
                itemType = 'game'                
            } else {
                itemType = _this.promoGame.type
            }

            if (itemType == 'promotion'){
                actionText = 'tovabb_a_promociora_kattintas'
                buttonText = 'tovabb_a_promociora'
            } else {
                actionText = 'tovabb_a_jatekhoz_kattintas'
                buttonText = 'tovabb_a_jatekhoz'
            }


            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': actionText,                
                'label': gameName,
                'page_type': _this.currentUrlName,
                'button': buttonText,       
                'clicked_text': 'Részletek',
                'game': gameName,
                'action_type': 'gomb_kattintas'
            })

            
        },
    }  
}
</script>